

.navbar{
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 2;
    box-shadow: 0px 18px 36px -19px rgba(0,0,0,0.4);
    nav {
        padding-left: 1rem;
        padding-right: 1rem;
        max-width: 80rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 5rem;
        @media (min-width: 640px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            height: 6rem;
        }
        .logoholder {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .logo {
                display: flex;
                align-items: center;
                img {
                    height: 2.5rem;
                    display: block;
                }
                .sitename {
                    display: none;
                    margin: 0 0.5rem;
                    text-align: left;
                    @media (min-width: 768px) {
                        display: block;
                        h1 {
                            font-size: 1.5rem;
                            color: #274066;
                        }
                        h2 {
                            font-weight: 600;
                            letter-spacing: .1em;
                            text-transform: uppercase;
                            font-size: .75rem;
                            color: #274066;
                        }
                    }
                }
            }
        }
        a {
            color: #274066;
            text-decoration: none;
            display: flex;
            align-items: center;
            .phone-icon {
                display: none;
                @media (min-width: 768px) {
                    display: block;
                }
                img {
                    width: 2rem;
                    height: 2rem;
                }
            }
            .phone-number-holder {
                margin: 0 0.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                .text-phone-number {
                    color: rgb(55, 65, 81);
                    letter-spacing: .1em;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: .75rem;
                }
                .toll-free-number-holder {
                    align-items: center;
                    display: flex;
                    position: relative;
                    .available-blinker {
                        display: block;
                        width: .5rem;
                        height: .5rem;
                        margin-right: .625rem;
                        border-radius: 9999px;
                        background-color: rgb(74, 222, 128);
                        @media (min-width: 768px) {
                            display: none;
                        }
                    }
                    .toll-free-number {
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        font-size: 1.25rem;

                        .font-bold {
                            font-weight: 700;
                            letter-spacing: -.05em;
                        }
                        .font-semibold {
                            font-weight: 600;
                            margin: 0 0.25em;
                        }
                    }
                }
            }
        }
    }
}
