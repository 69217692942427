

.rating-card{
    max-width: 31rem;
    min-height: 18rem;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    background-color: white;
    gap: 20px;
    transform: scale(0.93);
}